import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockIntroduction from "../components/blockIntroduction"
import TitleTextCol from "../components/titleTextCol"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-development-light.jpg"
import SitenuFavicon from "../images/favicon-sitenu.png"
import Accordions from "../components/accordions"
import ThreeImagesGallery from "../components/threeImagesGallery"
import Image from "../components/elements/image"
import { Container, Row, Col } from "react-bootstrap"
import LabelIntroduction from "../components/labelIntroduction"
import HeaderImageArrowDown from "../components/headerImageArrowDown"

const DevelopmentPage = props => {
  return (
    <Layout>
      <Seo
        title="Loyall – Met development verzorgen we technische oplossingen"
        image={{ src: MetaImage, height: 628, width: 1200 }}
        description="Hoe jouw bedrijf gebouwd is, bepaalt hoeveel succes je bereikt. Met development ontwikkelen we niet alleen websites die functioneel zijn, maar ook energiek. Alles om te de kans te vergroten dan bezoekers overgaan tot actie."
        pathname={props.location.pathname}
        favicon={SitenuFavicon}
      />
      <div className="page-wrapper">
        <LabelIntroduction
          label="development"
          title="We help you accelerate your brand."
          subtitle="Technische oplossingen zijn wel simpel, maar niet makkelijk. Het draait allemaal om het vertalen van beeld en verhaal naar code."
        />
        <Container className="image-header">
          <Image fadeIn={true} imgsrc="loyall-label-development-header.jpg"/>
        </Container>
        <TitleTextCol
          title="De manier waarop jouw bedrijf online gebouwd is, bepaalt hoeveel succes je boekt."
          paragh1="We ontwikkelen websites en applicaties die niet alleen functioneel zijn, maar ook energiek. Ze brengen geweldige designs tot leven en zorgen ervoor dat jouw bezoekers een holistische belevenis krijgen. Krachtige websites versterken je marketingacties en vergroten de kans dat bezoekers overgaan tot actie."
        />
        <BlockIntroduction
          title="Hoe het werkt"
          intro={
            <h3>
              Wij bouwen digitale bruggen voor een solide fundering.
            </h3>
          }
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
        />
        <HeaderImageArrowDown
          imageBig={"loyall-label-development-hoe-het-werk-1.jpg"}
          imageSmall={"loyall-label-development-hoe-het-werk-2.jpg"}
          elementId="development-two-images"
          delay={200}
          animationDuration={600}
        />
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 4 }}>
              <Accordions
                content={[
                  {
                    "title": "Building online content", 
                    "text": "Om online succes te behalen is het essentieel dat je content er niet alleen goed uitziet aan de voorkant, maar ook eenvoudig te beheren is vanuit de backend. Om eenvoud en gemak te waarborgen bouwen wij websites in verschillende Content Management Systemen.",
                    "list": ["Hubspot", "Wordpress", "Headless"]
                  },
                  {
                    "title": "Building digital stores", 
                    "text": "Dat je een website hebt betekent nog niet dat er klanten uitkomen. Hiervoor is het belangrijk dat jouw website de juiste tools heeft om potentiële klanten door de customer journey te laten gaan. Deze tools zullen helpen om de verkoop te verhogen en te verkopen via je online platform.",
                    "list": ["WooCommerce", "Custom webshops", "Online betaalmethodes", "CRM-koppelingen", "Tracking & automatisering"]
                  },
                  {
                    "title": "Custom webapplications", 
                    "text": "Een nieuw concept of idee dat op de kaart gezet moet worden? Of heb je beter zicht nodig op de output van je bedrijfsactiviteiten? Dan heb je een op maat gemaakte webapplicatie nodig waarmee jij je doelen kan behalen.",
                    "text_2": "Twee concepten die wij zelf hebben ontwikkeld zijn:",
                    "list": ["Platforms", "Dashboards", "SaaS"],
                    "list_2":["Site+", "Paige"]
                  },
                  {
                    "title": "Always available", 
                    "text": "De online wereld staat nooit stil. We zijn allemaal 24/7 online en verwachten dat onze systemen dat ook aankunnen. Offline zijn is het laatste wat mag gebeuren. Daarom bieden we verschillende oplossingen om dit te voorkomen.",
                    "list": ["Domeinnaam hosting", "E-mail hosting", "Webhosting", "CDN", "Uptime garanties tot 99.9%"]
                  }
                ]}
                padding={{ top: 60, bottom: 20, left: 15, right: 15 }}
              />
            </Col>
          </Row>
        </Container>
        <Cta
          verticalStyling={true}
          title=""
          intro="Klinkt goed toch? Check hier wat we al hebben gedaan."
          link="/"
          linkText="Binnenkort meer"
          className="pb-lg-0"
        />
        <ThreeImagesGallery
          imageBig={"loyall-label-development-portfolio-1.jpg"}
          imageSmall1={"loyall-label-development-portfolio-2.jpg"}
          imageSmall2={"loyall-label-development-portfolio-3.jpg"}
          padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
          delay={250}
          animationDuration={600}
        />
        <Cta
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default DevelopmentPage
